@font-face {
    font-family: lazyDog;
    src: url(../fonts/lazy_dog.ttf);
  }
  @import url('https://fonts.googleapis.com/css2?family=Geologica:wght,CRSV,SHRP@100,0.5,0;500,0.5,100;750,0.5,100&display=swap');
  /* font-family: 'Geologica', sans-serif;
  font-weight: 750;
  font-variation-settings: 'wght' 750;
  font-variation-settings: 'CRSV' 0.5;
  font-variation-settings:  'SHRP' 100; */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
  
  :root{
  --lightPurple: #BEA2CF;
  --orange: #F48B48;
  --white: #F7F3EA;
  --darkPurple: #460152; 
  }
  .NavbarContainer{
    display:flex;
    flex-direction: column;
  }
.navbar{
    font-family: 'lazyDog';
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    color: var(--darkPurple);
    height: 100px;
    font-size: 1.5vw;
}
.NavLeft{
    display: flex;
    align-items: flex-end;
}
.NavRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}
.Current{
    background-image: url('../images/0-navbar/mouse.png');
    background-size:cover;
    background-position: 0%;
    height: 70px;
    width: 150px;
    padding-top:20px;
    text-decoration: none;
    color: var(--darkPurple);
}
.Page{
    background: none;
    height: 70px;
    width: 100px;
    padding-top:10px;
    text-decoration: none;
    margin-left: 30px;
    margin-right: 10px;
    color: var(--lightPurple);
}