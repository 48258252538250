@font-face {
    font-family: lazyDog;
    src: url(../components/fonts/lazy_dog.ttf);
  }
  @import url('https://fonts.googleapis.com/css2?family=Geologica:wght,CRSV,SHRP@100,0.5,0;500,0.5,100;750,0.5,100&display=swap');
  /* font-family: 'Geologica', sans-serif;
  font-weight: 750;
  font-variation-settings: 'wght' 750;
  font-variation-settings: 'CRSV' 0.5;
  font-variation-settings:  'SHRP' 100; */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
  
  :root{
  --lightPurple: #BEA2CF;
  --orange: #F48B48;
  --white: #F7F3EA;
  --darkPurple: #460152; 
  }
  body{
    overflow-x:hidden;
  }
  .adoptionsTitle{
    margin-bottom:-150px;
    margin-top: 75px;
  }
  .homeTitle{
    letter-spacing: 2px;
    font-size: 4vw;
    margin-bottom: -125px;
  }
  .flowChart{
    margin-top: 175px;
  }
  .images{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .girlImg{
    width: 350px;
    align-self: flex-start;
    margin-left: 25vw;
    margin-top: 5vh;
    margin-bottom: -10vh;
  }
  .manImg{
    width:350px;
    align-self: flex-end;
    margin-right: 25vw;
    margin-top: -20vh;
  }
  .familyImg{
    width:350px;
    margin-left: 35vw;
    margin-top: 5vh;
  }
  .successStories{
    font-family: 'Poppins', sans-serif;
    font-weight: 450;
    font-size: 1.5vw;
  }
  .pastTitle{
    margin-bottom: -130px;
    margin-top: 40px;
    margin-left: -50vw;
  }