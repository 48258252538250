@font-face {
    font-family: lazyDog;
    src: url(../fonts/lazy_dog.ttf);
  }
  @import url('https://fonts.googleapis.com/css2?family=Geologica:wght,CRSV,SHRP@100,0.5,0;500,0.5,100;750,0.5,100&display=swap');
  /* font-family: 'Geologica', sans-serif;
  font-weight: 750;
  font-variation-settings: 'wght' 750;
  font-variation-settings: 'CRSV' 0.5;
  font-variation-settings:  'SHRP' 100; */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
  
  :root{
  --lightPurple: #BEA2CF;
  --orange: #F48B48;
  --white: #F7F3EA;
  --darkPurple: #460152; 
  }


body {
    background: #f7f3ea;
    font-family: "lazyDog";
}

h1 {
    font-size: 51.5px;
    color: #BEA2CF;
    padding-bottom: 0px;
    margin-bottom: 5px;
    margin-top: 30px;
}

p {
    font-family: 'DM Sans', sans-serif;
    padding-top: 2px;
    line-height: 5px;
}

.image-row-1, .image-row-2 {
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: space-around;

}

.cat-image {
    width: 275px;
    height: 275px;
    border-radius: 25px;
}

.grid-button{
    display: block;
    font-family: "lazyDog";
    background-color:#F48B48;
    border-radius: 25px;
    border: none;

}