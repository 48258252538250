@font-face {
    font-family: lazyDog;
    src: url(../fonts/lazy_dog.ttf);
  }
  @import url('https://fonts.googleapis.com/css2?family=Geologica:wght,CRSV,SHRP@100,0.5,0;500,0.5,100;750,0.5,100&display=swap');
  /* font-family: 'Geologica', sans-serif;
  font-weight: 750;
  font-variation-settings: 'wght' 750;
  font-variation-settings: 'CRSV' 0.5;
  font-variation-settings:  'SHRP' 100; */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
  
  :root{
  --lightPurple: #BEA2CF;
  --orange: #F48B48;
  --white: #F7F3EA;
  --darkPurple: #460152; 
  }
  .fetchCatsDiv {
    text-align: center;
    color: var(--darkPurple);
    font-family: 'lazyDog';
    background-color: var(--white);
    width:100vw;
  }
  .title{
    font-size:4vw;
    margin-bottom: -30px;
    color: var(--lightPurple);
    letter-spacing: 5px;
    text-shadow: -4px 3px 4px var(--darkPurple);
  }
  .cats{
    position:relative;
    display:flex;
    flex-direction: row;
    width: 100vw;
    padding-bottom: 20px;
  }
  .map{
  display:flex;
  flex-direction:column;
  align-items: center;
  width:100vw;
  }
  .catInfo{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5vw;
  }
  .catName{
    position: absolute;
    top:0;
    font-size:2vw;
    letter-spacing: 6px;
    margin-top: 2px;
    text-shadow: -2px -2px 0 var(--darkPurple), 2px -2px 0 var(--darkPurple), -2px 2px 0 var(--darkPurple), 2px 2px 0 var(--darkPurple);

  }
  
  .catImg{
    display:flex;
    width: 200px;
    height: 200px;
    border-radius: 25%;
  }
  .childCol{
    display:flex;
    flex-direction: column;
    align-items: center; 
    background-color: var(--darkPurple);
    border-radius: 55%;
    color: var(--white);
    position: absolute;
    top: 10%;
    left:-20%;
    animation: wobble 1.2s infinite;
    -moz-animation: wobble 1.2s infinite;
    -webkit-animation: wobble 1.2s infinite;
    -o-animation: wobble 1.2s infinite;
  }
  .dogCol{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: -80px;  
    background-color: var(--darkPurple);
    border-radius: 55%;
    color: var(--white);
    position: absolute;
    bottom:-10%;
    right:-10%;
    animation: wobble 1.2s infinite reverse;
    -moz-animation: wobble 1.2s infinite reverse;
    -webkit-animation: wobble 1.2s infinite reverse;
    -o-animation: wobble 1.2s infinite reverse;
  }
  .levelsHeader{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 725;
    line-height: 16px;
     width: 70px
  }
  .levelsValue{
    font-size: 1.2vw;
    font-weight: 100;
    margin-top: -2px;
  }
  
 
  #basketBtn{
    background-color: var(--orange);
    border-radius: 50px;
    width: 120px;
    height: 25px;
    font-size: 14px;
    margin-top: 10px;
    text-decoration: none;
    font-family: 'lazyDog';
    color: var(--darkPurple);
    border:none;
  }

  @keyframes wobble {  
    0% { transform: rotate(3.5deg)  } 
    50% { transform:  rotate(-3.5deg) } 
    100% { transform: rotate(3.5deg)  } 
  }
  @-o-keyframes wobble { 
    0% { transform: rotate(3.5deg)  } 
    50% { transform:  rotate(-3.5deg) } 
    100% { transform: rotate(3.5deg)  } 
  }
  @-moz-keyframes wobble { 
    0% { transform: rotate(3.5deg)  } 
    50% { transform:  rotate(-3.5deg) } 
    100% { transform: rotate(3.5deg)  } 
  }
  @-webkit-keyframes wobble {  
    0% { transform: rotate(3.5deg)  } 
    50% { transform:  rotate(-3.5deg) } 
    100% { transform: rotate(3.5deg)  } 
  }