@font-face {
    font-family: lazyDog;
    src: url(../fonts/lazy_dog.ttf);
  }
  @import url('https://fonts.googleapis.com/css2?family=Geologica:wght,CRSV,SHRP@100,0.5,0;500,0.5,100;750,0.5,100&display=swap');
  /* font-family: 'Geologica', sans-serif;
  font-weight: 750;
  font-variation-settings: 'wght' 750;
  font-variation-settings: 'CRSV' 0.5;
  font-variation-settings:  'SHRP' 100; */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
  
  :root{
  --lightPurple: #BEA2CF;
  --orange: #F48B48;
  --white: #F7F3EA;
  --darkPurple: #460152; 
  }
  #moreInfo{
    background-color: var(--orange);
    border-radius: 50px;
    width: 120px;
    height: 25px;
    font-family: 'lazyDog';
    font-size: 14px;
    margin-top: -45px;
    text-decoration: none;
    padding-top: 5px;
    color: var(--darkPurple);
    border: none;
  }
  .morecatName{
    display: flex;
    font-family: 'lazyDog';
    font-size: 3vw;
    justify-content: flex-end;
    margin-top: 150px;
  }
  .moreintro{
    font-family: 'lazyDog';
    font-size: 1.5vw;
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
  }
  .moreInfoBtn{
    margin-top: -20px;
  }
  .moreInfoModal{
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .modal{
    border-radius: 50%
  }
  .morecatInfo{
    max-width: 60vw;
    display:flex;
    flex-direction: column;
    margin-left: 20%;
    margin-top: -20px;
  }
  .morecatImg{
    margin-top: -50px;
    width: 300px;
    min-height:200px;
    height:auto;
    border-radius: 35%;
  }
  .data1, .data2{
    display:flex;
    flex-direction: row;
  }
  .legend{
    font-family: 'lazyDog';
    font-size: 1.4vw;
  }
  p, label{
    display: flex;
    flex-direction: column;
    font-family: 'poppins', sans-serif;
    font-weight:750;
  }
  .newDiv{
    display:flex;
    flex-direction:row;
  }
  .temp0, .temp2, .temp4{
    font-size: 25px;
    font-weight:500;
    margin-top: 40px;
    font-family: 'Geologica', sans-serif;
  font-variation-settings: 'CRSV' 0.5;
  font-variation-settings:  'SHRP' 100;
  }
  .temp1, .temp3{
    font-size: 25px;
    font-weight:450;
    margin-top: -10px;
  }
  .temp5{
    font-size: 25px;
    font-weight:450;
    margin-top: 100px;
    margin-left: -450px;
  }
  .temp6{
    font-size: 25px;
    font-weight:500;
    margin-top: 120px;
    font-family: 'Geologica', sans-serif;
  font-variation-settings: 'CRSV' 0.5;
  font-variation-settings:  'SHRP' 100;
  }
  .data1{
    min-width: 350px;
    margin-right: 25px;
    max-height: 140px;
    font-family: 'Geologica', sans-serif;
  }
  .data2{
    min-width:500px;
    max-height: 200px;
  }
  .movebackOrBasket{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
  }