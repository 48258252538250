@font-face {
    font-family: lazyDog;
    src: url(../fonts/lazy_dog.ttf);
  }
  @import url('https://fonts.googleapis.com/css2?family=Geologica:wght,CRSV,SHRP@100,0.5,0;500,0.5,100;750,0.5,100&display=swap');
  /* font-family: 'Geologica', sans-serif;
  font-weight: 750;
  font-variation-settings: 'wght' 750;
  font-variation-settings: 'CRSV' 0.5;
  font-variation-settings:  'SHRP' 100; */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
  
  :root{
  --lightPurple: #BEA2CF;
  --orange: #F48B48;
  --white: #F7F3EA;
  --darkPurple: #460152; 
  }
.trolley{
    height:40px;
    width:50px;
    display:flex;
   margin-top: 10px;
}
.mainBasket{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 30vw;
    margin-right: 10px;
    position:absolute;
    right:0;
    height: 100vh;
}
.close{
    display: flex;
    position: absolute;
    top: 5%;
    right: 2%;
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(200px);
    transition: all 2000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
    transform: translateX(0px)
}

.ReactModal__Overlay--before-close{
    opacity: 0;
    transform: translateX(200px)
}

.basketItem{
    display: flex;
    flex-direction: row;
}
.basketRight{
    display:flex;
    flex-direction: column;
}

@keyframes slide-in{
    from { transform: translateX(350px)}
    to { transform: translateX(-10px)}
}
@keyframes slide-out{
    from { transform: translateX(-10px)}
    to { transform: translateX(350px)}
}
